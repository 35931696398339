import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

function popover(item) {
    return (
        <Popover id="popover-basic">
            <Popover.Header as="h3">{item.titlt}</Popover.Header>
            <Popover.Body>
                {item.description}
            </Popover.Body>
        </Popover>
    )
};

function Team({ systemTheme }) {
    const teamList = require('../../lib/media/teamList.json');

    return (
        <Container id="teamPage">
            <Row className='text-center'>
                <Col xs="12">
                    <h1 className='page-title-box'>חברי הפורום</h1>
                </Col>
            </Row>
            <section className="scene_element scene_element--fadein">
                <Row className="align-items-center">
                    {teamList.map((item, i) => {
                        const imageSrc = item.src || 'logo.png';
                        return (<Col lg="3" xs="12" key={"team-" + i}>
                            <Card className='team-card' key={"team-" + i} data-bs-theme={systemTheme === "dark-theme" ? "dark" : ""}>
                                <OverlayTrigger trigger="click" placement="bottom" overlay={popover(item)}>
                                        <Image variant="top" src={`../images/team-images/${imageSrc}`} alt={item.title} roundedCircle="true" />
                                </OverlayTrigger>
                                <Card.Body>
                                    <Card.Title>{item.title}</Card.Title>
                                    <Card.Text title={item.description}>
                                        {item.sortDescription}
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>)
                    })}
                </Row>
            </section>
        </Container>
    );
}

export default Team;