import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button';

import aboutUsPdf from '../../lib/pdf/דף_מסרים_סיוון_תשפד.pdf';

function AboutUs({ setSelectedPage, systemTheme }) {
  return (
    <Container id="aboutPage">
      <section className="scene_element scene_element--fadein">
        <Row>
          <Col sm="12">
            <Card id="mainCard">
              <Card.Body>
                <Card.Title className="text-center">
                  <h1 className='main-title'>פורום עוטף ישראל</h1>
                </Card.Title>
                <Card.Text>
                  <Row>
                    <Col xs="12" className='text-right'>
                      <Accordion defaultActiveKey="0" data-bs-theme={systemTheme === "dark-theme" ? "dark" : ""}>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header><h2>מטרת הפורום</h2></Accordion.Header>
                          <Accordion.Body>
                            פורום עוטף ישראל קם במטרה <br />
                            לייצג את תושבי העוטף ולהשמיע את קולם בקריאה לא עוד! <br />
                            אנו קוראים למהפכה בתפיסת הביטחון, <br />
                            <b className='text-turquoise'>מהתמגנות והתגוננות להכרעה והסרת האיום.</b>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header><h2>קצת עלינו:</h2></Accordion.Header>
                          <Accordion.Body>
                            <p>
                              פורום עוטף ישראל הינה התאגדות של תושבי ותושבות העוטף, <br />
                              מהישובים ברשויות העוטף השונות (אשכול, שער הנגב, שדות נגב, חוף אשקלון ושדרות) <br />
                              שחברו יחד בתחילת ינואר 2024 לאחר ארועי הטבח בשביעי לאוקטובר  <br />
                              מתוך רצון לייצר קול ברור ומאוחד של תושבי העוטף. בפורום חברים נשים וגברים ממגוון אוכלוסיות.
                            </p>
                            <p>
                              הפורום מונה כ-2,000 תושבים מיישובי העוטף החברים במטות מחאה שונים שקמו <br />
                              ועובד בשיתופי פעולה עם כל היוזמות האזרחיות ברחבי המרחב.
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header><h2>מבנה הפורום</h2></Accordion.Header>
                          <Accordion.Body>
                            <p>
                              את הפורום מנהל צוות מוביל, הפועל באינטנסיביות למימוש החזון, <br />
                              כאשר כל אחד מהצוות מוביל קבוצת עבודה בתחומים השונים: <br />
                              שטח ומחאה, מדיה, תוכן, דוברות, תקשורת וכנסת, קשרי חוץ, גיוס משאבים ואדמיניסטרציה.
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header><h2>חזון</h2></Accordion.Header>
                          <Accordion.Body>
                            <p>
                            חזוננו הוא מהפכה בתפיסת הביטחון, ממדיניות של התמגנות להסרת האיום באופן מוחלט. 
                            </p>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                          <Accordion.Header><h2>יעדים</h2></Accordion.Header>
                          <Accordion.Body>
                            <p>
                              מימוש יעדי המלחמה כפי שהוגדרו: <br />
                              1. הסרת איום ארגוני טרור ושימור הישגי הקרב. <br />
                              2. השבת שכנינו וחברינו החטופים. <br />
                            </p>
                            <h4> מוזמנים לקרוא עוד עלינו ב
                              <a href={aboutUsPdf} className='btn-block' target='_blank' rel="noreferrer">
                                דף המסרים
                              </a>
                              שלנו
                            </h4>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </Col>
                  </Row>
                  <Row className="text-center">
                    <Col xs="12">
                      <a href='#donate' onClick={() => { setSelectedPage('#donate') }} rel="noreferrer">
                        <Button variant="success" className="btn-lg donate-button">אני רוצה לתרום</Button>
                      </a>
                      {" "}
                      <a href='#contact' onClick={() => { setSelectedPage('#contact') }} rel="noreferrer">
                        <Button variant="primary" className="btn-lg">צור קשר</Button>
                      </a>
                    </Col>
                  </Row>
                </Card.Text>
              </Card.Body>
            </Card>
            {/* <iframe src={aboutUsPdf} style={{ width: "100%", height: "100%", minHeight:"100vh", border: "none" }}></iframe> */}
          </Col>
        </Row>

      </section>
    </Container>
  );
}

export default AboutUs;