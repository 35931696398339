import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import { isMobile } from './lib/consts';

function toogleSystemTheme(setSystemTheme, systemTheme) {
  let newSystemThem = systemTheme === "light-theme" ? "dark-theme": "light-theme";
  localStorage.setItem('systemTheme', newSystemThem);
  setSystemTheme(newSystemThem);
}
function topNavbar({ setSelectedPage, setSystemTheme, systemTheme }) {

  const systemThemeLink =
    <Nav.Link
      className={"system-theme-link " + systemTheme}
      onClick={() => { toogleSystemTheme(setSystemTheme, systemTheme); }}>
      {systemTheme === "light-theme" ? "מצב כהה" : "מצב בהיר"}
    </Nav.Link>;
  return (
    <Navbar expand="lg" bg="primary" data-bs-theme="dark" fixed="top" className='middle' collapseOnSelect={true}
      onSelect={(selectedKey) => { setSelectedPage(selectedKey) }}
    >
      <Container style={{ direction: "rtl" }}>
        <Navbar.Toggle aria-controls="top-navbar" className="justify-content-between" />
        <Navbar.Collapse id="top-navbar">
          <Nav
            className="me-auto my-2 my-lg-0 "
            navbarScroll
          >
            <Nav.Link href="/#">בית</Nav.Link>
            <Nav.Link href="#media">בתקשורת</Nav.Link>
            <Nav.Link href="#events">אירועים</Nav.Link>
            <Nav.Link href="#about-us">אודות</Nav.Link>
            <Nav.Link href="#team">חברי הפורום</Nav.Link>
            <Nav.Link href="#donate">תרומות</Nav.Link>
            <Nav.Link href="#contact">צור קשר</Nav.Link>
            {!isMobile && systemThemeLink}
          </Nav>
        </Navbar.Collapse>
        {isMobile && systemThemeLink}
      </Container>
    </Navbar>
  );
}

export default topNavbar;