import { setDoc, getDoc, doc } from 'firebase/firestore';
import firebaseStore from '../components/common/lib/FirbaseStoreConfig';

const BannerService = {
    async getMisslesBannerDetails() {
        // Reference to a specific document
        const docRef = doc(firebaseStore, 'banners', 'missilesBanner');

        let missilesBannerDetails = {};
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            missilesBannerDetails = docSnap.data();
            console.log("Document data:", docSnap.data());
        }
        return missilesBannerDetails;
    },
    updateBannerDetails(docObj) {
        return setDoc(doc(firebaseStore, "banners", 'missilesBanner'), docObj, { merge: true });
    }
}

export default BannerService;