// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAik9yWRATOW7y3TtOJ0H2PXKyjFiOrb0c",
  authDomain: "forumotefisrael-a7622.firebaseapp.com",
  projectId: "forumotefisrael-a7622",
  storageBucket: "forumotefisrael-a7622.appspot.com",
  messagingSenderId: "942583360577",
  appId: "1:942583360577:web:b16aa025535bfc5ab7eac0",
  measurementId: "G-L93VE8GFQ2"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
const FirbaseStore = getFirestore(firebaseApp);

export default FirbaseStore;
// const analytics = getAnalytics(app);