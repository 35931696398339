const FormService = {
    getFormData(form, formDetails) {
        const formData = {};
        formDetails.forEach((item) => {
            formData[item] = (form[item] && form[item].value) || '';
            form[item].value = "";
        })
        return formData;
    },
}

export default FormService;