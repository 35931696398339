import { useState } from 'react';

import BannerContainer from '../common/BannerContainer';
import BannerModal from '../common/BannerModal';

function Banner() {
  const [showBannerModalFlag, setBannerModalFlag] = useState(false);

  return (
    <div>
      <BannerContainer switchBannerModal={() => {setBannerModalFlag(!showBannerModalFlag)}}></BannerContainer>
      {showBannerModalFlag && <BannerModal setBannerModalFlag={setBannerModalFlag} />}
    </div>
  )
}

export default Banner;