import { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image';
import Carousel from 'react-bootstrap/Carousel';

import MediaService from '../services/MediaService';

import { isMobile } from './common/lib/consts';
import ImagesCarousel from './common/ImagesCarousel';
import AboutUsSection from '../components/common/AboutUsSection';
import otefIsraelHomeTitleImage from '../images/מהתמגנות להסרת האיום.jpg';
import otefIsraelHomeTitleWhiteImage from '../images/מהתמגנות להסרת האיום לבן.jpeg';
import Banner from '../components/pages/Banner';

// main roles icons
import hostages from '../images/home-icons/hostages.png';
import fullSecurity from '../images/home-icons/full-security.png';
import improveSecurity from '../images/home-icons/improve-security.png';
import lastWar from '../images/home-icons/last-war.png';
import military from '../images/home-icons/military.png';
import victory from '../images/home-icons/victory.png';

function Home({ setSelectedPage, systemTheme }) {
    const [mediaList, setMediaList] = useState([]);

    useEffect(() => {
        MediaService.getMediaList('images').then((mediaList) => {
            setMediaList(mediaList);
        });
    }, []);

    return (
        <Container id="home-page">
            <section id="home-page-section-1" className="home-page-section scene_element scene_element--fadein">
                <br />
                <Row className='scene_element scene_element--fadein text-center'>
                    <Col xs={12} id="home-title" className='p0'>
                        <Image src={systemTheme === "light-theme" ? otefIsraelHomeTitleWhiteImage : otefIsraelHomeTitleImage} className='middle' rounded />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div id="otef-banner-container">
                            <hr />
                            <div id="banner">
                                <Banner></Banner>
                            </div>
                        </div>
                    </Col>
                </Row>
                <hr />
                <section id="home-about-us-section">
                    <AboutUsSection setSelectedPage={setSelectedPage}></AboutUsSection>
                </section>
                <hr />
                {!isMobile &&
                    <Row>
                        <Col lg="12" className='p0'>
                            <h1 className='title-box sub-title mt0'>
                                <a href='#donate' onClick={() => { setSelectedPage('#donate') }}>הפורום בשטח</a>
                            </h1>
                        </Col>
                        <Col lg="12" className='p0'>
                            <ImagesCarousel mediaList={mediaList}></ImagesCarousel>
                        </Col>
                    </Row>}
                {isMobile && <Row>
                    <Col xs="12">
                        <h1 className='title-box sub-title'>
                            <a href='#donate' onClick={() => { setSelectedPage('#donate') }}>הפורום בשטח</a>
                        </h1>
                    </Col>
                    <Col xs="12">
                        <Carousel data-bs-theme="dark" className="carousel-container text-center scene_element scene_element--fadeinup">
                            {mediaList && mediaList.map((item, i) => {
                                return (
                                    <Carousel.Item key={i}>
                                        <Card> <img
                                            key={i}
                                            className=""
                                            src={`../images/home-images/${item.src}`}
                                            alt={item.title}
                                        />
                                            <Card.Title>{item.title}</Card.Title>
                                        </Card>
                                    </Carousel.Item>
                                );
                            })}
                        </Carousel>
                    </Col>
                </Row>}
            </section>
            <hr />
            <section id="home-page-section-2" className="home-page-section scene_element scene_element--fadeinup text-center">
                <h1 className='main-title'>כוחנו באחדותנו<span className='text-blue'>.</span></h1>
                <h2 className='sub-title text-blue'>עקרונות ויעדי הפורום</h2>
                <Row>
                    <Col xs="6" lg="4">
                        <div className='logo-box'><Image src={hostages} /></div>
                        <h2>השבת החטופים</h2>
                    </Col>
                    <Col xs="6" lg="4">
                        <div className='logo-box'><Image src={fullSecurity} /></div>
                        <h2>ביטחון מלא
                            <br />
                            לתושבי ישראל והעוטף
                        </h2>
                    </Col>
                    <Col xs="6" lg="4" className="desktop">
                        <div className='logo-box'><Image src={improveSecurity} /></div>
                        <h2>שינוי תפיסת הביטחון</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs="6" lg="4" className="mobile">
                        <div className='logo-box'><Image src={improveSecurity} /></div>
                        <h2>שינוי תפיסת הביטחון</h2>
                    </Col>
                    <Col xs="6" lg="4">
                        <div className='logo-box'><Image src={victory} /></div>
                        <h2>נצחון ארוך טווח</h2>
                    </Col>
                    <Col xs="6" lg="4">
                        <div className='logo-box'><Image src={military} /></div>
                        <h2>השבת ההרתעה</h2>
                    </Col>
                    <Col xs="6" lg="4">
                        <div className='logo-box'><Image src={lastWar} /></div>
                        <h2>מלחמת עזה האחרונה</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" lg="12">
                        <h2 className='title-box sub-title mb0'>בטחון = בלי  צבע אדום! בלי רוצחים מעבר לגדר!</h2>
                    </Col>
                </Row>

            </section>
            <hr />
            <section id="home-page-section-3" className="scene_element scene_element--fadeinup text-center">
                <Row>
                    <Col xs="12">
                        <h1 className='main-title'>הצטרפו לעשייה</h1>
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col xs="6" lg="6">
                        <a href='#donate' onClick={() => { setSelectedPage('#donate') }} rel="noreferrer">
                            <div className='logo-box'><i className="bi bi-chat-heart"></i></div>
                            <h2>תרמו לפעילות</h2>
                        </a>
                    </Col>
                    <Col xs="6" lg="6">
                        <a href='#contact' onClick={() => { setSelectedPage('#contact') }} rel="noreferrer">
                            <div className='logo-box'><i className="bi bi-chat-fill"></i></div>
                            <h2>צור קשר</h2>
                        </a>
                    </Col>
                </Row>
            </section>
        </Container>

    );
}

export default Home;
