import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import React, { useState } from 'react';
import ContactService from '../../services/ContactService'
import FormService from '../../services/FormService';

import LandingPage from './LandingPage';

function Contact() {

    const [validated, setValidated] = useState(false);
    const [submittedFlag, setSubmittedFlag] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            return false;
        }

        const formDetails = ['fullName', 'city', 'phone', 'email', 'textarea'];
        const contactFormData = FormService.getFormData(form, formDetails);
        const date = new Date();
        contactFormData.date = date.toLocaleString();
        const response = ContactService.submitContactForm(contactFormData);
        if (response) {
            setSubmittedFlag(true);
            setValidated(false);
        }
    };

    return (<Container id="contactPage">
        <Form id="contactForm" validated={validated} onSubmit={handleSubmit} className="scene_element--1s scene_element--fadein">
            <Row className='text-center'>
                <Col xs="12">
                    <h1 className='page-title-box'>צור קשר</h1>
                </Col>
            </Row>
            <Row className="align-items-center">
                <Col>
                    <Form.Group className="mb-3" controlId="contactForm.fullName">
                        <Form.Label>שם מלא</Form.Label>
                        <Form.Control name="fullName" type="text" required />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="contactForm.city">
                        <Form.Label>ישוב</Form.Label>
                        <Form.Control name="city" type="text" required />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="contactForm.phone">
                        <Form.Label>טלפון נייד</Form.Label>
                        <Form.Control name="phone" type="phone" required />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="contactForm.email">
                        <Form.Label>דואר אלקטרוני</Form.Label>
                        <Form.Control name="email" type="email" required />
                        <Form.Control.Feedback type="invalid">
                            נא מלא דואר אלקטרוני תקין
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="contactForm.textarea">
                        <Form.Label>משהו נוסף</Form.Label>
                        <Form.Text name="textarea" as="textarea" rows={3} className="form-control" />
                    </Form.Group>
                    <br className="desktop-large" />
                    <div className="d-grid gap-2">
                        {submittedFlag && <h3 className='text-success'>הפרטים נשלחו בהצלחה!</h3>}
                        <Button type="submit" className="btn-block btn-lg" >שלח</Button>
                    </div>
                </Col>
            </Row>
        </Form>
        <LandingPage isContactPage></LandingPage>
    </Container>);
}

export default Contact;
