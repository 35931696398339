
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import React, { useState } from 'react';
import FormService from '../../services/FormService'
import { getAuth, signInWithEmailAndPassword, inMemoryPersistence, setPersistence } from "firebase/auth";

const auth = getAuth();
auth.languageCode = 'he';

function Login({ setSelectedPage }) {

    const [validated, setValidated] = useState(true);
    const [submittedFlag, setSubmittedFlag] = useState(false);
    const [loginErrorFlag, setLoginErrorFlag] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            return false;
        }

        const formDetails = ['email', 'password'];

        const formData = FormService.getFormData(form, formDetails);

        setPersistence(auth, inMemoryPersistence)
            .then(() => {
                return signInWithEmailAndPassword(auth, formData.email, formData.password)
                    .then((userCredential) => {
                        // Signed in 
                        const user = userCredential.user;
                        if (user) {
                            // sessionStorage.setItem('email', formData.email);
                            // sessionStorage.setItem('password', formData.password);
                            setLoginErrorFlag(false);
                            setSubmittedFlag(true);
                            setValidated(false);
                            setTimeout(() => {
                                setSelectedPage('#admin');
                                window.location.hash='#admin';
                            }, 2000);
                        }
                    })
            })
            .catch((error) => {
                setValidated(false);
                setLoginErrorFlag(true);
            });
    };

    return (<Container id="loginPage">
        <br className="desktop-large" /><br />
        <Form id="loginForm" validated={validated} onSubmit={handleSubmit} className="scene_element--1s scene_element--fadein">
            <Row className='text-center'>
                <Col xs="12">
                    <h1 className='page-title-box'>כניסה</h1>
                </Col>
            </Row>
            <br className="desktop-large" />
            <Row className="align-items-center">
                <Col>
                    <Form.Group className="mb-3" controlId="loginForm.email">
                        <Form.Label>שם משתמש</Form.Label>
                        <Form.Control name="email" type="text" required />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="loginForm.password">
                        <Form.Label>סיסמא</Form.Label>
                        <Form.Control name="password" type="password" required />
                    </Form.Group>
                    <br className="desktop-large" />
                    <div className="d-grid gap-2">
                        {submittedFlag && <h3 className='text-success'>פרטי הכניסה נכונים!</h3>}
                        {loginErrorFlag && <h3 className='text-danger'>פרטי הכניסה שגויים!</h3>}
                        <Button type="submit" className="btn-block btn-lg" >שלח</Button>
                    </div>
                </Col>
            </Row>
        </Form>
    </Container>);
}

export default Login;
