import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

function MediaItem({item}) {
    return <Col xs="12" sm="12" lg="6" >
        <Card>
            <Card.Body>
                <iframe width="100%" height="300" src={item.src} title={item.title} allowFullScreen="true"></iframe>
            </Card.Body>
            <Card.Title>{item.title}</Card.Title>
        </Card>
    </Col>
}
export default MediaItem;