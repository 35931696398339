import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


import MediaService from '../../../services/MediaService';

function Videos({ setSelectedPage}) {
    const [editMode, setEditMode] = useState(false);
    const [mediaList, setMediaList] = useState(false);
    // To delete this paremeter!!!!
    const [sessionParameter, setSessionParameter] = useState(false);

    useEffect(() => {
        MediaService.getMediaList('videos').then((mediaList) => {
            setMediaList(mediaList);
        });
        // if (mediaList.length < 10) {
        //     MediaService.updateMediaList('videos', currentMediaList);
        // }
    }, []);

    function onFieldChange(e, fieldName, i) {
        let newValue = e.target.value;
        e.preventDefault();
        e.stopPropagation();
        if (fieldName === "order") {
            newValue = Number.parseInt(newValue);
        }
        if (fieldName === "src" && !newValue.includes("=")) {
            const newValueParts = newValue.split("=");
            newValue = `https://www.youtube.com/embed/${newValueParts[1]}`;
        }
        mediaList[i][fieldName] = newValue;
        setMediaList(mediaList);
    }
    function saveMediaList(setEditMode) {
        setEditMode(false);
        mediaList.sort((a, b) => {
            return (a.order - b.order)
        });
        let newMediaList = mediaList.map((item, i) => {
            item.order = i + 1;
            return item;
        });
        setMediaList(newMediaList);
        setSessionParameter(!sessionParameter);
    }
    function addNewRow() {
        mediaList.push({
            order: mediaList.length + 1,
            src: "",
            title: "",
        });
        setMediaList(mediaList);
        setSessionParameter(!sessionParameter);
    }
    function deleteRow(index, deleted) {
        mediaList[index].deleted = deleted;
        setMediaList(mediaList);
        setSessionParameter(!sessionParameter);
    }
    async function updateMediaList() {
        saveMediaList(setEditMode);
        await MediaService.updateMediaList('videos', mediaList);
    }

    return (
        <Container className="admin-continer">
            <Row>
                <Col xs="12">
                    <h1 className='page-title-box'>סירטוני וידאו</h1>
                    <div className="mb-2 text-center">
                        <Button variant="outline-warning" size="lg" onClick={() => { setSelectedPage("#admin"); }}>מערכת ניהול</Button> {" "}
                        <Button variant="primary" size="lg" onClick={() => { setEditMode(true); }}>עריכה</Button> {" "}
                        <Button variant="success" size="lg" onClick={() => { updateMediaList(); }}>שמור</Button> {" "}
                        {editMode && <Button variant="outline-primary" size="lg" onClick={() => { addNewRow(); }}>הוסף שורה</Button>}
                    </div>
                    <div className="hide">{sessionParameter}</div>
                    <Form id="VideoForm">
                        <Table striped bordered hover variant="dark">

                            <thead>
                                <tr>
                                    <th>סדר</th>
                                    <th>כותרת</th>
                                    <th>קישור</th>
                                    {editMode && <th></th>}
                                </tr>
                            </thead>
                            <tbody>
                                {mediaList && mediaList.map((item, i) => {
                                    if (!editMode && !item.deleted) {
                                        return (<tr key={i}>
                                            <td>{item.order}</td>
                                            <td>{item.title}</td>
                                            <td>{item.src}</td>
                                        </tr>)
                                    } else if (editMode) {
                                        return (<tr key={i}>
                                            <td style={{ maxWidth: "20px" }}>
                                                <Form.Control type="text" placeholder="סדר" defaultValue={item.order} onChange={(e) => { onFieldChange(e, 'order', i) }} />
                                            </td>
                                            <td><Form.Control type="text" placeholder="כותרת" defaultValue={item.title} onChange={(e) => { onFieldChange(e, 'title', i) }} /></td>
                                            <td><Form.Control type="text" placeholder="קישור" defaultValue={item.src} onChange={(e) => { onFieldChange(e, 'src', i) }} /></td>
                                            {!item.deleted && <td> <Button variant="danger" onClick={() => { deleteRow(i, true) }} className="middle btn-block">מחק</Button></td>}
                                            {item.deleted === true && <td> <Button variant="warning" onClick={() => { deleteRow(i, false) }} className="middle btn-block">שחזר</Button></td>}
                                        </tr>)
                                    }
                                })}
                            </tbody>
                        </Table>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default Videos;