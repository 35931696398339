import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useState, useEffect } from 'react';
import { getAuth } from "firebase/auth";
import ContactService from '../../../services/ContactService';
import gmailLogo from "../../../images/logos/gmail.png"

const subject = "הצטרפות לפורום עוטף ישראל";
const body = "אני מעוניין לשמוע עוד מידע נוסף על פורום עוטף ישראל";
async function getContacts(setcontactsList) {
    const contactsListResponse = await ContactService.getContacts()
    setcontactsList(contactsListResponse);
}
function ContactsView({ setSelectedPage }) {
    let [contactsList, setcontactsList] = useState([]);

    useEffect(() => {
        getContacts(setcontactsList);
    }, []);

    function updateRowField(index, fieldName, fieldValue) {
        contactsList = [...contactsList]
        contactsList[index] = { ...contactsList[index] }
        contactsList[index][fieldName] = fieldValue;
        ContactService.updateContactRow(contactsList[index]).then(() => {
            setcontactsList(contactsList);
        })
    }

    return (
        <Container className="admin-continer">
            <Row>
                <Col xs="12">
                    <h1 className='page-title-box'>הסטוריית צור קשר</h1>
                    <div className="mb-2 text-center">
                        <Button variant="outline-warning" size="lg" onClick={() => { setSelectedPage("#admin"); }}>מערכת ניהול</Button> {" "}
                    </div>
                    <Form id="contactsForm">
                        <Table striped bordered hover variant="dark">
                            <thead>
                                <tr>
                                    <th>תאריך</th>
                                    <th>אימייל</th>
                                    <th>שם מלא</th>
                                    <th>טלפון</th>
                                    <th>עיר</th>
                                    <th>הערה</th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {contactsList.map((item, i) => {
                                    // if (!item.deleted) {
                                    return (<tr key={i}>
                                        <td>{item.date}</td>
                                        <td>{item.email}</td>
                                        <td>{item.fullName}</td>
                                        <td>{item.phone}</td>
                                        <td>{item.city}</td>
                                        <td>{item.textarea}</td>
                                        <td>
                                            <a href={`mailto:${item.email}?subject=${subject}&body=${body}`} target="_blank" rel="noreferrer">
                                                <img src={gmailLogo} className="d-inline-block align-top icon" alt="אימייל" />
                                            </a>
                                        </td>
                                        {<td>
                                            <Button variant={item.handled ? "success" : "primary"}
                                                disabled={item.handled} onClick={() => { updateRowField(i, 'handled', true) }}
                                                className="middle btn-block"> {item.handled ? "טופל" : "לטיפול"}
                                            </Button>
                                        </td>}
                                    </tr>)
                                })}
                            </tbody>
                        </Table>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default ContactsView;