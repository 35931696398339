import Carousel from "react-bootstrap/Carousel";
import Stack from "react-bootstrap/Stack";
import Card from "react-bootstrap/Card";

function ImagesCarousel({ mediaList }) {
  const mediaListLen = mediaList.length;

  if (mediaList.length < 3) {
    return;
  }
  const mediaArray = [];
  for (let index = 0; index < mediaListLen; index += 3) {
    mediaArray.push(mediaList.slice(index, index + 3));
  }

  return (
    <Carousel
      data-bs-theme="dark"
      className="carousel-container text-center scene_element scene_element--fadeinup"
    >
      {mediaArray.map((mediaSlice, i) => {
        return (
          <Carousel.Item id={`item1${i}`} key={`item1${i}`}>
            <Stack
              direction="horizontal"
              className="h-100 justify-content-center align-items-center"
              gap={3}
            >
              {mediaSlice.map((item, j) => {
                const cardItem = (
                  <Card key={j}>
                    {" "}
                    <img
                      className=""
                      src={`../images/home-images/${item.src}`}
                      alt={item.title}
                    />
                    <Card.Title>{item.title}</Card.Title>
                  </Card>
                );

                if (item.link) {
                  return (
                    <a href={item.link} target="_blank" rel="noreferrer">
                      {cardItem}
                    </a>
                  );
                } else {
                    return cardItem;
                }
              })}
            </Stack>
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}

export default ImagesCarousel;
