import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useState, useEffect } from 'react';
import FormService from '../../../services/FormService';
import BannerService from '../../../services/BannerService';

async function getMisslesBannerDetails(setBannerForm) {
    const misslesBannerDetails = await BannerService.getMisslesBannerDetails()
    setBannerForm(misslesBannerDetails);
}
function BannerManager({ setSelectedPage }) {
    let [bannerForm, setBannerForm] = useState({});

    const [validated, setValidated] = useState(false);
    const [submittedFlag, setSubmittedFlag] = useState(false);

    useEffect(() => {
        getMisslesBannerDetails(setBannerForm);
    }, []);
    const handleChange = (e) => {
        setBannerForm({
            ...bannerForm,
            [e.target.name]: e.target.value
        });
    };
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        if (form.checkValidity() === false) {
            return false;
        }
        const formDetails = ['counter'];
        const bannerFormData = FormService.getFormData(form, formDetails);
        const date = new Date();
        bannerFormData.date = date.toLocaleString();
        const response = BannerService.updateBannerDetails(bannerFormData);
        if (response) {
            setSubmittedFlag(true);
            setValidated(false);
            getMisslesBannerDetails(setBannerForm);
        }
    };

    return (
        <Container className="admin-continer">
            <Row>
                <Col xs="12">
                    <h1 className='page-title-box'>באנר</h1>
                    <div className="mb-2 text-center">
                        <Button variant="outline-warning" size="lg" onClick={() => { setSelectedPage("#admin"); }}>מערכת ניהול</Button> {" "}
                    </div>
                    <Form id="bannerForm" validated={validated} onSubmit={handleSubmit} className="scene_element--1s scene_element--fadein">
                        <Row className="align-items-center">
                            <Col>
                                <Form.Group className="mb-3" controlId="bannerForm.counter">
                                    <Form.Label>מספר מטחי טילים</Form.Label>
                                    <Form.Control name="counter" onChange={handleChange}  value={bannerForm.counter} type="text" required />
                                </Form.Group>
                                <div className="d-grid gap-2">
                                    {submittedFlag && <h3 className='text-success'> הבאנר התעדכן בהצלחה!</h3>}
                                    <Button type="submit" className="btn-block btn-lg" >שלח</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

export default BannerManager;