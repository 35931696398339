import { Container, Row, Col } from 'react-bootstrap';
function Banner({ switchBannerModal }) {
    return (
        <div>
            <Container style={{ minHeight: "auto", maxWidth: "100vw" }} >
                <Row>
                    <Col>
                        <h1 className='title'>גם אצלכם יש קיר מסתור ליד ביה"ס?</h1>
                        <h1>מאז ה 7/10 נורו על שדרות<b id='missile-count' className='large-text'> 209 </b> מטחי רקטות!</h1>
                        <h4>
                            <b>שינוי תפיסת הביטחון - מהתמגנות להסרת האיום!</b>
                            <a className="btn btn-link" onClick={() => { switchBannerModal() }} >
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-copy" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z" />
                                </svg>
                            </a>
                        </h4>

                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default Banner;