import { collection, query, getDocs, addDoc, setDoc, doc } from 'firebase/firestore';
import firebaseStore from '../components/common/lib/FirbaseStoreConfig';


const ContactService = {
    results: [],
    getFormData(form, formDetails) {
        const contactFormData = {};
        formDetails.forEach((item) => {
            contactFormData[item] = (form[item] && form[item].value) || '';
            form[item].value = "";
        })
        return contactFormData;
    },
    async submitContactForm(contactFormData) {
        const contactsCollection = collection(firebaseStore, 'contacts');
        const newDocRef = await addDoc(contactsCollection, contactFormData);
        return newDocRef;
    },
    async getContacts() {
        const q = query(collection(firebaseStore, "contacts"));
        const querySnapshot = await getDocs(q);
        const results = [];
        querySnapshot.forEach(function (doc) {
            const docObj = doc.data();
            docObj.id = doc.id;
            results.push(docObj);
        });
        return results
    },
    updateContactRow(docObj) {
        return setDoc(doc(firebaseStore, "contacts", docObj.id), docObj, { merge: true });
    }
}

export default ContactService;