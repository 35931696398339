import React, { useState, useEffect } from 'react';

import './scss/app.scss';
import HeaderNavbar from './components/common/HeaderNavbar';
import BottomNavbar from './components/common/FooterNavbar';
import Home from './components/Home';
import Contact from './components/pages/Contact';
import Media from './components/pages/Media';
import Donate from './components/pages/Donate';
import Partners from './components/pages/Partners';
import Events from './components/pages/Events';
import AboutUs from './components/pages/About';
import Team from './components/pages/Team';
import LandingPage from './components/pages/LandingPage';
import Banner from './components/pages/Banner';
import BannerModal from './components/common/BannerModal';

// Admin pages
import Login from './components/pages/Login';
import Admin from './components/pages/admin/Admin';
import Videos from './components/pages/admin/Videos';
import Images from './components/pages/admin/Images';
import ContactsView from './components/pages/admin/ContactsView';
import BannerManager from './components/pages/admin/BannerManager';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import UserService from './services/UserService';

const auth = getAuth();

function getCurrentPage(selectedPage, setSelectedPage, systemTheme) {
  let page = <Home setSelectedPage={setSelectedPage} systemTheme={systemTheme} />;
  switch (selectedPage) {
    case "#contact":
      page = <Contact />
      break;
    case "#media":
      page = <Media />
      break;
    case "#donate":
      page = <Donate setSelectedPage={setSelectedPage} />
      break;
    case "#partners":
      page = <Partners />
      break;
    case "#events":
      page = <Events />
      break;
    case "#about-us":
      page = <AboutUs setSelectedPage={setSelectedPage} systemTheme={systemTheme} />
      break;
    case "#team":
      page = <Team systemTheme={systemTheme} />
      break;
    case "#landing-page":
      page = <LandingPage setSelectedPage={setSelectedPage} />
      break;
    case "#banner":
      page = <Banner setSelectedPage={setSelectedPage} />
      break;
  }
  return page;
}
function getAdminCurrentPage(selectedPage, setSelectedPage, currentUser) {
  let page = <Login setSelectedPage={setSelectedPage} />;
  if (!currentUser || selectedPage === "#admin/login") {
    return page;
  }
  UserService.setWindowBeforeunload();
  switch (selectedPage) {
    case "#admin":
      page = <Admin setSelectedPage={setSelectedPage} />
      break;
    case "#admin/videos":
      page = <Videos setSelectedPage={setSelectedPage} />
      break;
    case "#admin/images":
      page = <Images setSelectedPage={setSelectedPage} />
      break;
    case "#admin/contacts":
      page = <ContactsView setSelectedPage={setSelectedPage} />
      break;
    case "#admin/banner":
      page = <BannerManager setSelectedPage={setSelectedPage} />
      break;
  }
  return page;
}

const page = window.location.hash;
let initSystemTheme = "dark-theme";
if (localStorage.hasOwnProperty('systemTheme')) {
  initSystemTheme = localStorage.getItem('systemTheme');
}
function App() {
  const [selectedPage, setSelectedPage] = useState(page);
  const [currentPage, setCurrentPage] = useState(<Home />);
  const [currentUser, setCurrentUser] = useState(null);
  const [systemTheme, setSystemTheme] = useState(initSystemTheme);

  useEffect(() => {

    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user)
    });
    /*
    if (isAdminPages.includes(selectedPage)) {
      if (sessionStorage.hasOwnProperty('email')) {
        const email = sessionStorage.getItem('email');
        const password = sessionStorage.getItem('password');
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            setCurrentUser(userCredential.user)
          }, (error) => {
            sessionStorage.clear('email');
            sessionStorage.clear('password');
            setSelectedPage('#login');
          });
      } else {
        setSelectedPage('#login');
        window.location.href = "/#login";
      }
    }
    */
  });
  useEffect(() => {
    const isAdminPage = selectedPage.includes('admin');
    let page;
    if (!isAdminPage) {
      page = getCurrentPage(selectedPage, setSelectedPage, systemTheme, currentUser)
    } else {
      page = getAdminCurrentPage(selectedPage, setSelectedPage, currentUser)
    }

    setCurrentPage(page);

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [selectedPage, systemTheme]);

  //End Get current page:
  const currentPageId = selectedPage.replace('#', '');

  const isExternalPages = ["landing-page", "banner"].includes(currentPageId);

  return (
    <div className={"app-root m-scene " + systemTheme} id={currentPageId}>
      {!isExternalPages && <header id="app-header">
        <HeaderNavbar
          setSelectedPage={setSelectedPage}
          setSystemTheme={setSystemTheme}
          systemTheme={systemTheme}
          currentPageId={currentPageId}>
        </HeaderNavbar>
      </header>}
      {<div className="main">{currentPage}</div>}
      {!isExternalPages && <footer id="app-footer">
        <BottomNavbar setSelectedPage={setSelectedPage}></BottomNavbar>
      </footer>}
    </div>
  );
}

export default App;
