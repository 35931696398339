import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useEffect } from 'react';
import { getAuth } from "firebase/auth";


const auth = getAuth();

function Admin({ setSelectedPage, currentUser }) {
    useEffect(() => {
        if (!currentUser) {
            // setSelectedPage('#login');
        }
    });
    function signOut() {
        auth.signOut().then(function () {
            console.log('User signed out.');
            setSelectedPage('#admin/login');
            // sessionStorage.clear('email');
            // sessionStorage.clear('password');
        });
    }
    return (
        <Container className="admin-continer">
            <Row>
                <Col xs="12" className='text-center'>
                    <h1 className='page-title-box'>ניהול</h1>
                    <a href="/#admin/videos"><Button variant="primary" onClick={() => { setSelectedPage('#admin/videos'); }}>סירטוני וידאו</Button></a>
                    {" "}
                    <a href="/#admin/images"><Button variant="primary" onClick={() => { setSelectedPage('#admin/images'); }}>תמונות</Button> </a>
                    {" "}
                    <a href="/#admin/contacts"><Button variant="primary" onClick={() => { setSelectedPage('#admin/contacts'); }}>הסטוריית יצירת קשר</Button></a>
                    {" "}
                    <a href="/#admin/banner"><Button variant="primary" onClick={() => { setSelectedPage('#admin/banner'); }}>באנר</Button></a>
                    {" "}
                    <Button variant="danger" onClick={signOut}>יציאה</Button>
                </Col>
            </Row>
        </Container>
    );
}

export default Admin;