import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import facebookLogo from "../../images/logos/facebook.png"
import instagramLogo from "../../images/logos/instagram.png"
import whatsappLogo from "../../images/logos/whatsapp.png"
import youtubeLogo from "../../images/logos/youtube.png"
import twitterLogo from "../../images/logos/twitter.png"
import gmailLogo from "../../images/logos/gmail.png"

function FooterNavbar() {
  return (
    <Navbar expand="lg" bg="primary" data-bs-theme="dark" className='navbar-expand middle'
      // fixed="bottom"
      onSelect={(selectedKey) => { console.log(selectedKey) }}
    >
      <Container style={{ direction: "rtl" }}>
        {/* <Navbar.Brand href="/" onClick={() => { setSelectedPage('#home') }}>
              <img src={logo} className="d-inline-block align-top footerIcon" alt="בית" />
            </Navbar.Brand> */}
        <Navbar.Brand href="https://www.facebook.com/forumotefil" target="_blank">
          <img src={facebookLogo} className="d-inline-block align-top footerIcon" alt="פייסבוק" />
        </Navbar.Brand>
        <Navbar.Brand href="https://www.instagram.com/forum_otef_israel" target="_blank">
          <img src={instagramLogo} className="d-inline-block align-top footerIcon" alt="אינסטגרם" />
        </Navbar.Brand>
        <Navbar.Brand href="https://chat.whatsapp.com/IN501enbMfLBTk25FGHP1Q" target="_blank">
          <img src={whatsappLogo} className="d-inline-block align-top footerIcon" alt="וואטסאפ" />
        </Navbar.Brand>
        <Navbar.Brand href="https://www.youtube.com/@forumotefisrael" target="_blank">
          <img src={youtubeLogo} className="d-inline-block align-top footerIcon" alt="יוטיוב" />
        </Navbar.Brand>
        <Navbar.Brand href="https://twitter.com/forumotefisrael" target="_blank">
          <img src={twitterLogo} className="d-inline-block align-top footerIcon" alt="טוויטר" />
        </Navbar.Brand>
        <Navbar.Brand href="mailto:forumotefisrael@gmail.com?subject=הצטרפות לפורום עוטף ישראל&body=אני מעוניין לשמוע עוד מידע נוסף על פורום עוטף ישראל" >
          <img src={gmailLogo} className="d-inline-block align-top footerIcon" alt="אימייל" />
          {/* <div className='home-main-role-icon'><i className="bi bi-chat text-blue" onClick={() => { setSelectedPage('#contact') }}></i></div> */}
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}

export default FooterNavbar;