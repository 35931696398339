import { Row, Col, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

function BannerModal({ setBannerModalFlag }) {
    const popupStyle = `<style>
            #close {position: fixed;top: 20px;right: 30px;font-size: 25px;cursor: pointer;}
            #otef-banner-container {position: fixed;top: 50%;left: 50%;transform: translate(-50%, -50%);z-index: 100000;}
            #otef-banner-container iframe {max-width: 94vw;border-radius: 20px;}
        </style>`;
    const bannerStyle = `<style>
        #otef-banner-container iframe {max-width: 94vw;border-radius: 20px;}
    </style>`
    const closeButton = `<b id="close" onclick="hideBanner()">x</b>`;
    const hideBannerScript = `<script>function hideBanner() { document.getElementById('otef-banner-container').style.display = 'none'; }</script>`;
    const getBannerText = (isPopup) => {
        const popupBanner = `<div id="otef-banner-container"> ${isPopup ? closeButton : ''}
    <iframe src='${window.location.origin + "/#banner"}'
        width="820" height="285" title="Embedded Content">
    </iframe>
${isPopup ? popupStyle + hideBannerScript : bannerStyle}
</div>`;
        return popupBanner;
    }
    const handleClose = () => {
        setBannerModalFlag(false);
    }
    return (
        <div
            id="banner-modal"
        >
            <Modal data-bs-theme="dark" show={true} size="lg" direction="ltr" onHide={handleClose}>
                <Modal.Header closeButton>
                    <h2>העתקת באנר התרעות פיקוד העורף</h2>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <Form>
                                <Form.Group>
                                    <h3><Form.Label>חלון קופץ:</Form.Label></h3>
                                    <Form.Control
                                        as="textarea"
                                        rows={12}
                                        value={getBannerText(true)}
                                        style={{ direction: "ltr" }}
                                    />
                                </Form.Group>
                                <Form.Group>
                                    <h3><Form.Label>באנר רגיל :</Form.Label></h3>
                                    <Form.Control
                                        as="textarea"
                                        rows={8}
                                        value={getBannerText()}
                                        style={{ direction: "ltr" }}
                                    />
                                </Form.Group>
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="secondary" >
                        Close
                    </Button> */}

                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default BannerModal;