import { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { isMobile } from '../common/lib/consts'

import MediaService from '../../services/MediaService';
import MediaItem from '../MediaItem'

function Media() {
    const [mediaList, setMediaList] = useState([]);

    useEffect(() => {
        MediaService.getMediaList('videos').then((mediaList) => {
            setMediaList(mediaList);
        });
    }, []);

    if (!isMobile) {
        return (
            <Container id="media-page" className="text-center">
                <section className="scene_element--2s scene_element--fadein">
                    <Row className='text-center'>
                        <Col lg="12">
                            <h1 className='page-title-box'>פעילות התנועה</h1>
                        </Col>
                    </Row>
                    <Row className="media-row scene_element--4s scene_element--fadeinup">
                        {mediaList.map((item, i) => {
                            return <MediaItem key={i} item={item}></MediaItem>;
                        })}
                    </Row>
                </section>
            </Container>)
    } else {
        return (
            <Container id="media-page" className="text-center">
                <section className="scene_element--2s scene_element--fadein">
                    <h1 className='page-title-box'>פעילות התנועה</h1>
                    {mediaList.map((item, i) => {
                        return (
                            <Row key={i} className="media-row">
                                <MediaItem item={item}></MediaItem>
                            </Row>
                        )
                    })}
                </section>
            </Container>)
    }
}

export default Media;
