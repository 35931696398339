import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Partners() {
  return (
    <Container>
      <Row>
        <Col sm="12">
            <h1>שותפים</h1>
        </Col>
      </Row>
    </Container>
  );
}

export default Partners;