import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function AboutUsSection({ setSelectedPage }) {
    return (
        <Row>
            <Col xs={12} id="about-section" className='p0'>
                <Card>
                    <Card.Body className='scene_element scene_element--fadein '>
                        <Card.Title>
                            <h1 className='main-title text-center'>קצת עלינו</h1>
                        </Card.Title>
                        <Card.Text>
                            <p>
                                <b className='sub-title text-blue'>
                                    פורום עוטף ישראל הינו גוף א-פוליטי המאגד תושבים מהעוטף במטרה:
                                </b>
                            </p>
                            <p>
                                ⬅️לפעול למהפכה בתפיסת הביטחון שהביאה לטבח השביעי באוקטובר <br />
                                <b> ממדיניות של התמגנות  למדיניות הסרת האיום באופן מוחלט </b>.
                            </p>
                            <p>
                                ⬅️ זוהי שעה היסטורית ואסור לנו להחמיץ את ההזדמנות לשנות את עתיד העוטף. <br />
                                לא נמשיך לגדל דורות נוספים <b >תחת טילים והפקרה בטחונית</b>.
                            </p>
                            <p>
                                ⬅️ חברי פורום עוטף ישראל דורשים לעצור את מדיניות המיגון ומדיניות התגובה לאויב. <br />
                                אנו דורשים שמי שינהל את מרחב חיינו מהיום והלאה <b>מדינת ישראל ולא החמאס</b>.
                            </p>
                            <p>
                                ⬅️ בתור השכנים של האויב הרצחני, נהיה שומרי הסף, <br />  לוודא שבמערכה הזו יהיו הפעם <b>הכרעה ונצחון</b>.
                            </p>
                            <h4 className="text-center">
                                <a href='#about-us' onClick={() => { setSelectedPage('#about-us') }} rel="noreferrer">
                                    <b className='sub-title invert'>למידע נוסף &gt;</b>
                                </a>
                            </h4>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

export default AboutUsSection;