import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Donate() {
  return (
    <Container>
      <Row>
        <Col xs="12">
          <iframe src="https://pe4ch.com/ref/RYvCESaE2che?lang=he" style={{ width: "100%", height: "100%", minHeight: "100vh", border: "none"}} title="donate"></iframe>
        </Col>
      </Row>
    </Container>
  );
}

export default Donate;